<template>
  <div class="publicHeaderPC">
    <div class="publicHeaderPC-header">
      <img class="publicHeaderPC-logo" src="@/assets/image/public/header.png" />
      <form class="publicHeaderPC-search">
        <input placeholder="请输入关键字进行搜索" type="text" />
        <iconfont
          class="publicHeaderPC-search-icon"
          name="search"
          size="24px"
        />
      </form>
      <img class="publicHeaderPC-phone" src="@/assets/image/public/phone.png" />
    </div>

    <div class="publicHeaderPC-nav">
      <div class="publicHeaderPC-nav-list">
        <router-link
          to="/home"
          class="publicHeaderPC-nav-item"
          :class="{ 'publicHeaderPC-nav-item_active': $route.name == 'home' }"
          >爱月宝首页</router-link
        >
        <router-link
          to="/brand"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'brand' || $route.name == 'brand',
          }"
          >品牌介绍</router-link
        >
        <router-link
          to="/caregiver"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'caregiver' || $route.name == 'caregiver',
          }"
          >月嫂</router-link
        >
        <router-link
          to="/nanny"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'nanny' || $route.name == 'nanny',
          }"
          >育婴师</router-link
        >
        <router-link
          to="/postpartum"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'postpartum' || $route.name == 'postpartum',
          }"
          >产后康复</router-link
        >
        <router-link
          to="/university"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'university' || $route.name == 'university',
          }"
          >月嫂课堂</router-link
        >
        <!--router-link
          to="/joinUs"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'joinUs' || $route.name == 'joinUs',
          }"
          >招商加盟</router-link
        >-->
        <router-link
          to="/about/job"
          class="publicHeaderPC-nav-item"
          :class="{
            'publicHeaderPC-nav-item_active':
              $route.meta.nav == 'about' || $route.name == 'about',
          }"
          >加入我们</router-link
        >
      </div>
    </div>
  </div>

  <div class="publicHeaderMobile">
    <div class="publicHeaderMobile-header">
      <div class="publicHeaderMobile-header-button" @click="back">
        <iconfont name="back" size="50rpx" />
      </div>
      <div class="publicHeaderMobile-header-title">{{ $route.meta.name }}</div>
      <div
        class="publicHeaderMobile-header-button"
        @click="showList = !showList"
      >
        <iconfont name="menu" size="50rpx" />
      </div>
    </div>
    <div class="publicHeaderMobile-list" v-if="showList">
      <router-link
        to="/home"
        class="publicHeaderMobile-item"
        :class="{ 'publicHeaderMobile-item_active': $route.name == 'home' }"
        >爱月宝首页</router-link
      >
      <router-link
        to="/brand"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'brand' || $route.name == 'brand',
        }"
        >品牌介绍</router-link
      >
      <router-link
        to="/caregiver"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'caregiver' || $route.name == 'caregiver',
        }"
        >月嫂</router-link
      >
      <router-link
        to="/nanny"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'nanny' || $route.name == 'nanny',
        }"
        >育婴师</router-link
      >
      <router-link
        to="/postpartum"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'postpartum' || $route.name == 'postpartum',
        }"
        >产后康复</router-link
      >
      <router-link
        to="/university"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'university' || $route.name == 'university',
        }"
        >月嫂课堂</router-link
      >
      <!--router-link
        to="/joinUs"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'joinUs' || $route.name == 'joinUs',
        }"
        >招商加盟</router-link
      >-->
      <router-link
        to="/about/job"
        class="publicHeaderMobile-item"
        :class="{
          'publicHeaderMobile-item_active':
            $route.meta.nav == 'about' || $route.name == 'about',
        }"
        >加入我们</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "publicHeader",
  props: {
    detail: String,
  },
  data() {
    return {
      showList: false,
    };
  },
  unmounted() {
    // 销毁事件
    removeEventListener("click", this.cancelMenu);
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      addEventListener("click", this.cancelMenu);
    },
    /**
     * 取消菜单
     */
    cancelMenu(e) {
      if (!document.querySelector(".publicHeaderMobile").contains(e.target)) {
        this.showList = false;
      }
    },

    /**
     * 后退
     */
    back() {
      console.log(this.$router)
      this.$router.back();
      // this.$router.replace({ name: "home" });
    },
  },
};
</script>


<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .publicHeaderMobile {
    display: none;
  }

  .publicHeaderPC {
    min-width: 1200px;

    // 顶部
    &-header {
      height: 124px;
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    // 搜索
    &-search {
      margin-top: 49px;
      height: 44px;
      width: 484px;
      border: 2px solid #ed207b;
      border-radius: 23px;
      background-color: #fff;
      position: relative;

      input {
        background-color: transparent;
        border: none;
        display: block;
        width: stretch;
        height: stretch;
        outline: none;
        padding-left: 20px;
        padding-right: 50px;
      }

      &-icon {
        position: absolute;
        pointer-events: none;
        right: 19px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    // 导航
    &-nav {
      background-color: #ed207b;

      // 导航列表
      &-list {
        width: 1200px;
        margin: auto;
        display: flex;
        line-height: 66px;
      }

      // 导航元素
      &-item {
        color: #fff;
        flex: 1;
        text-align: center;

        &_active {
          background-color: #9bdbda;
        }
      }
    }
  }
}
</style>


<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .publicHeaderPC {
    display: none;
  }

  .publicHeaderMobile {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 101;

    // 顶部
    &-header {
      height: rpx(90);
      width: 100%;
      color: #ed207b;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-button {
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    // 导航列表
    &-list {
      position: absolute;
      top: rpx(90);
      left: 0;
      right: 0;
      z-index: 1001;
      line-height: rpx(90);
      background-color: #fff;
    }

    // 导航元素
    &-item {
      display: block;
      color: #404040;
      font-size: rpx(28);
      border-bottom: 1px dashed #f2f2f2;
      text-align: center;

      &_active {
        color: #fff;
        background-color: #9bdbda;
      }
    }
  }
}
</style>
