<template>
  <div class="publicBanner">
    <img class="publicBanner-img" :src="src" />
  </div>
</template>

<script>
export default {
  name: "publicBanner",
  props: {
    src: {
      type: String,
      default: require("@/assets/image/public/publicBanner.jpg"),
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .publicBanner {
    width: 100%;
    min-width: 1200px;
    height: 484px;

    img {
      @include cover;
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .publicBanner {
    width: 100%;
    height: rpx(300);

    img {
      @include cover;
    }
  }
}
</style>
