<template>
  <div class="breadcrumb">
    <div class="breadcrumb-frame">
      <div class="breadcrumb-router-list">
        <iconfont class="breadcrumb-router-icon" size="20px" name="home" />
        您所在的位置：
        <span
          class="breadcrumb-router-item"
          v-for="item in router"
          :key="item"
          >{{ item }}</span
        >
      </div>
      <div class="breadcrumb-nav-list" v-if="nav.length">
        <router-link
          :to="{ name: item.route }"
          class="breadcrumb-nav-item"
          :class="{ 'breadcrumb-nav-item_active': $route.name == item.route }"
          v-for="item in nav"
          :key="item.route"
        >
          {{ item.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumb",
  props: {
    router: Array,
    nav: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .breadcrumb {
    height: 78px;
    border-bottom: 1px solid #f2f2f2;

    &-frame {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-router {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 10px;
      }

      &-list {
        color: #666666;
        display: flex;
        align-items: center;
      }

      &-item {
        &:not(:last-of-type)::after {
          content: "-";
        }

        &:last-of-type {
          color: #ed207b;
        }
      }
    }

    &-nav {
      height: 100%;

      &-list {
        display: flex;
        align-items: center;
        height: 100%;
      }

      &-item {
        height: 100%;
        cursor: pointer;
        width: 158px;
        background-color: #9bdbd9;
        color: #fff;
        text-align: center;
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

        &_active {
          background-color: #ed207b;

          &::after {
            background-color: #ed207b;
            position: absolute;
            content: "";
            display: block;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            width: 18px;
            height: 12px;
            top: 100%;
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .breadcrumb {
    &-router {
      &-list {
        display: none;
      }
    }

    &-nav {
      &-list {
        display: flex;
        align-items: center;
        height: rpx(80);
        padding: rpx(20) rpx(10);
      }

      &-item {
        width: 0;
        flex: 1;
        margin: 0 rpx(10);
        cursor: pointer;
        background-color: #9bdbd9;
        color: #fff;
        text-align: center;
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;

        &_active {
          background-color: #ed207b;

          &::after {
            background-color: #ed207b;
            position: absolute;
            content: "";
            display: block;
            clip-path: polygon(0 0, 50% 100%, 100% 0);
            width: 18px;
            height: 12px;
            top: 100%;
          }
        }
      }
    }
  }
}
</style>
